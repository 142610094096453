import { render, staticRenderFns } from "./DialogAddOtherOverhead.vue?vue&type=template&id=d2304c88&scoped=true"
import script from "./DialogAddOtherOverhead.vue?vue&type=script&lang=js"
export * from "./DialogAddOtherOverhead.vue?vue&type=script&lang=js"
import style0 from "./DialogAddOtherOverhead.vue?vue&type=style&index=0&id=d2304c88&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2304c88",
  null
  
)

export default component.exports